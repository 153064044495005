@import "./bootstrap-overrides";

/**************************************
    BUTTONS
**************************************/
.sic-btn {
  // padding: 4px 26px 4px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
  text-align: center;
  text-decoration: none;
  @include transitionEaseIn(0.3s);
  &:hover,
  &:focus {
    text-decoration: none;
  }
}
.sic-btn-primary {
  background-color: $primary;
  color: $white;
  border: 1px solid $primary;
}
.sic-btn-secondary {
  background-color: $secondary2;
  color: $white;
  border: 1px solid $secondary2;
}
.sic-btn-primary,
.sic-btn-secondary {
  &:after {
    background: $white;
    content: "";
    height: 155px;
    left: -75px;
    opacity: 0.2;
    position: absolute;
    top: -50px;
    transform: rotate(35deg);
    transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
    width: 50px;
  }
  &:hover {
    color: $white;
    &:after {
      left: 120%;
      transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
    }
  }
}
.sic-btn-white {
  border: 1px solid $secondary2;
  color: $secondary2;
  background-color: $white;
  &:hover {
    background-color: $secondary2;
    color: $white;
  }
}
.sic-btn-outline-danger {
  border: 1px solid $danger;
  color: $danger;
  background-color: $white;
  &:hover {
    background-color: $danger;
    color: $white;
  }
}
.sic-btn-outline-success {
  border: 1px solid $success;
  color: $success;
  background-color: $white;
  &:hover {
    background-color: $success;
    color: $white;
  }
}
.sic-btn-light-purple {
  background-color: $transparent;
  color: $secondary2;
  border: 1px solid $secondary2;
  @include transition(0.3s);
  &:hover {
    background-color: $secondary2;
    color: $white;
  }
}

/*******************************
    HAMBURGER
*******************************/
.hamburger {
  display: none;
  width: 40px;
  position: relative;
  height: 21px;
  margin-left: 32px;
  @include breakpoint(ipad) {
    display: block;
  }
  @include breakpoint(small-tab) {
    margin-left: 16px;
  }
  @media only screen and (max-width: 340px) {
    width: 30px;
  }
  .line {
    position: absolute;
    right: 0;
    height: 2px;
    background-color: $secondary2;
    transition: 0.3s;
  }
  .line1 {
    width: 100%;
    top: 0;
  }
  .line2 {
    width: 50%;
    top: 9px;
    opacity: 1;
    visibility: visible;
  }
  .line3 {
    width: 75%;
    top: 18px;
  }
}
body.menu-btn-clicked {
  .hamburger {
    .line1 {
      top: 9px;
      transform: rotate(-45deg);
    }
    .line2 {
      transform: translateX(50px);
      opacity: 0;
      visibility: hidden;
      top: 9px;
    }
    .line3 {
      top: 9px;
      transform: rotate(45deg);
      width: 100%;
    }
  }
}

/**************************************
    VERIFICAION CODE CONTAINER
**************************************/
.verification-code-container {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  max-width: 336px;
  @include breakpoint(mobile) {
    margin-left: 0;
    margin-right: 0;
  }
  .single-code-box {
    padding: 0px 12px;
    width: 25%;
    .form-control {
      min-height: 60px;
      text-align: center;
      border-radius: 20px;
      border-color: $secondary2;
      font-size: 30px;
      font-weight: bold;
      &:focus {
        //   border: 1px solid $secondary2;
      }
    }
  }
}

/**************************************
    BREADCRUMB
**************************************/
.sic-breadcrumb {
  li {
    color: $primary;
    a {
      text-decoration: none;
      color: $primary;
      font-size: 18px;
      @include transition(0.3s);
      &:hover {
        color: $secondary2;
      }
      // @include breakpoint(large-laptop) {
      //   font-size: 14px;
      // }
    }
  }

  .breadcrumb-item + .breadcrumb-item {
    padding-left: 1.25rem;
    font-size: 18px;
    color: $gray;
    @include breakpoint(large-laptop) {
      font-size: 14px;
      padding-left: 1rem;
    }
    &:before {
      content: "\f101";
      font-family: "FontAwesome";
      font-weight: bold;
      padding-right: 1.25rem;
      height: 24px;
      margin-top: 1px;
      @include breakpoint(large-laptop) {
        padding-right: 1rem;
      }
    }
  }
}

/**************************************
    CARDS
**************************************/

/**************************************
    SEARCH BOX
**************************************/
.sic-search-box {
  form,
  .search-box-content {
    display: flex;
    width: 99%;
    .form-control {
      border-radius: 4px 0 0 4px;
      background-color: $white;
      font-size: 14px;
      @include breakpoint(large-laptop) {
        font-size: 14px;
      }
      ::placeholder {
        color: #858585;
      }
      &:focus {
        border-color: $secondary2;
      }
    }
    button {
      border-radius: 0px 4px 4px 0px;
      min-width: 56px;
    }
  }
}
::placeholder {
  color: black !important;
}
.mat-select-placeholder {
  color: black !important;
}

/**************************************
    FIELD WITH BUTTON
**************************************/
.field-with-btn {
  form {
    display: flex;
    .form-floating {
      max-width: 500px;
      width: 100%;
      input {
        background-color: $white;
        &:focus {
          border-color: $primary;
        }
      }
    }
    button {
      margin-left: 16px;
      @include breakpoint(x-large-laptop) {
        min-width: 90px;
      }
    }
  }
}

/**************************************
    VIDEO BOX
**************************************/
.video-box {
  position: relative;
  min-height: 280px;
  height: 100%;
  border-radius: 5px;
  overflow: hidden;
  img:not(.video-icon) {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .video-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    width: 23%;
  }
}

/**************************************
    TABLES
**************************************/
.sic-table {
  width: 100%;
  tr {
    th,
    td {
      border-color: #dee2e6;
      vertical-align: middle;
      .form-check {
        .form-check-input {
          margin-top: 0;
        }
      }
      &.action-col {
        width: 110px;
      }
    }
    th {
      // todo
      color: #9DA8B4;
      padding: 10px 7px;
      background-color: #F5F9FA ;
      font-weight: 500;
      font-size: 12px;
      &:first-child {
        border-radius: 8px 0 0 0;
      }
      &:last-child {
        border-radius: 0 8px 0 0;
      }
    }
    td {
      padding: 14px 16px;
      font-size: 14px;
      border-bottom: 1px solid $light-gray;
      @include breakpoint(large-laptop) {
        font-size: 12px;
        padding: 10px 8px;
      }
    }
    // &:last-child {
    //   td {
    //     border-bottom: 0;
    //   }
    // }
    .table-actions {
      a {
        margin-right: 8px;
        cursor: pointer;
        img {
          width: 18px;
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
    .table-status{
      img{
        width: 18px;
      }
    }
  }
}

/**************************************
    TABLE SCROLLER
**************************************/
.table-box {
  overflow-x: auto;
  @extend .stylish-scroll;
}
.scroll-from-x-large-laptop {
  table {
    @include breakpoint(x-large-laptop) {
      width: 1599px;
    }
  }
}
.scroll-from-large-laptop {
  table {
    @include breakpoint(large-laptop) {
      width: 1440px;
    }
  }
}
.scroll-from-laptop {
  table {
    @include breakpoint(laptop) {
      width: 985px;
    }
  }
}
.scroll-from-small-laptop {
  table {
    @include breakpoint(small-laptop) {
      width: 1199px;
    }
  }
}
.scroll-from-ipad {
  table {
    @include breakpoint(ipad) {
      width: 991px;
    }
  }
}
.scroll-from-tab {
  table {
    @include breakpoint(tab) {
      width: 767px;
    }
  }
}
.scroll-from-small-tab {
  table {
    @include breakpoint(small-tab) {
      width: 575px;
    }
  }
}
.scroll-from-mobile {
  table {
    @include breakpoint(mobile) {
      width: 480px;
    }
  }
}
.scroll-from-small-mobile {
  table {
    @include breakpoint(small-mobile) {
      width: 365px;
    }
  }
}

/*******************************
    DROPDOWN
*******************************/
.sic-dropdown-menu {
  border-radius: 0;
  border: 0;
  inset: 23px auto auto 18px !important;
  @include box-shadow();
  @include breakpoint(x-large-laptop) {
    inset: 20px auto auto 22px !important;
  }
  .hf-profile-container {
    text-align: center;
    .hf-profile-img {
      margin: 0 auto;
      width: 70px;
      height: 70px;
      overflow: hidden;
      border-radius: 12px;
      margin-bottom: 4px;
      margin-top: 8px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .hf-profile-name {
      font-size: 18px;
    }
  }
  .separator {
    height: 1px;
    background-color: #c9c9c9;
    margin: 4px 24px;
  }
  li {
    a {
      display: block !important;
      text-decoration: none;
      button {
        font-size: 16px;
        color: #7f7f7f;
        padding-left: 50px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        white-space: break-spaces;
        padding: 8px 24px;
        border-left: 4px solid $transparent;
        border-right: 4px solid $transparent;
        background-color: $transparent;
        @include transition(0.3s);
        .icon {
          display: flex;
          align-items: center;
          width: 20px;
          margin-right: 10px;
          i {
            font-size: 20px;
          }
        }
        .text {
          display: block;
          width: calc(100% - 30px);
        }
        &:hover {
          border-left: 4px solid $secondary2;
          background-color: #e4ecf3;
          color: $secondary2;
        }
      }
      &:hover a {
        color: $secondary2;
      }
    }
  }
}

/*******************************
    MULTISELECT
*******************************/
.select2-container {
  min-width: 275px;
  .selection {
    width: 100%;
  }
}
.select2-container--default
  .select2-selection--multiple
  .select2-selection__rendered {
  padding: 0;
}
.select2-results__option {
  padding-right: 20px;
  vertical-align: middle;
  padding: 6px 6px 6px 10px;
  display: flex;
  align-items: center;
}
.select2-results__option:before {
  content: "";
  display: inline-block;
  position: relative;
  height: 20px;
  width: 20px;
  border: 1px solid $success;
  border-radius: 4px;
  background-color: #fff;
  margin-right: 20px;
  vertical-align: middle;
}
.select2-results__option[aria-selected="true"]:before {
  font-family: fontAwesome;
  content: "\f00c";
  color: #fff;
  border: 1px solid $success !important;
  background-color: $success;
  border: 0;
  display: inline-block;
  padding-left: 3px;
  font-size: 12px;
  padding-top: 1px;
}
.select2-container--default .select2-results__option[aria-selected="true"] {
  background-color: #fff;
}
.select2-container--default
  .select2-results__option--highlighted[aria-selected] {
  background-color: $secondary2;
  color: $white;
  &:before {
    border: 1px solid $secondary2 !important;
  }
}
.select2-container--default.select2-container--open.select2-container--below
  .select2-selection--multiple {
  border-radius: 4px;
}
.select2-container--default.select2-container--focus
  .select2-selection--multiple {
  border-color: $secondary2;
}
.select2-container--default .select2-selection--multiple {
  border: 1px solid #e5e9ec;
  transition: 0.5s;
}
.select2-container--open .select2-dropdown--below {
  border-radius: 4px;
  border: 1px solid #e5e9ec;
}
.select2-selection .select2-selection--multiple:after {
  content: "hhghgh";
}
/* select with icons badges single*/
.select-icon .select2-selection__placeholder .badge {
  display: none;
}
.select-icon .placeholder {
  /*  display: none; */
}
.select-icon .select2-results__option:before,
.select-icon .select2-results__option[aria-selected="true"]:before {
  display: none !important;
  /* content: "" !important; */
}
.select-icon .select2-search--dropdown {
  display: none;
}
.select2-container--default .select2-search--inline .select2-search__field {
  min-height: 28px;
  min-width: 273px !important;
  width: 100% !important;
  padding: 0 12px;
}
.select2-container--default .select2-results > .select2-results__options {
  @extend .stylish-scroll;
  &::-webkit-scrollbar-thumb {
    background-color: #8f6cf682;
  }
}
.select2-container--default
  .select2-selection--multiple
  .select2-selection__choice {
  border: 1px solid $secondary2;
  font-size: 12px;
  background-color: $white;
}

/*******************************
    TABS
*******************************/

/* underlined style */
.sic-nav-tabs {
  border-bottom: 1px solid $transparent;
  .nav-item {
    margin-right: 70px;
    @include breakpoint(x-large-laptop) {
      margin-right: 35px;
    }
    @include breakpoint(large-laptop) {
      margin-right: 24px;
    }
    .nav-link {
      font-size: 20px;
      padding: 0px 0px 12px 0px !important;
      color: $gray;
      position: relative;
      @include breakpoint(x-large-laptop) {
        font-size: 16px;
      }
      @include breakpoint(large-laptop) {
        font-size: 14px;
        padding: 6px 0 !important;
      }
      @include breakpoint(small-laptop) {
        font-size: 12px;
      }
      &:after {
        content: "";
        width: 0px;
        height: 4px;
        border-radius: 4px;
        background-color: $secondary2;
        @include transition(0.2s);
        position: absolute;
        bottom: -1px;
        left: 0;
      }
      &.active {
        border-color: $transparent;
        color: $primary;
        font-weight: 700;
        &:after {
          width: 100%;
        }
      }
      &:hover {
        border: 1px solid $transparent;
      }
    }
    &.show {
      .nav-link {
        border-color: $transparent;
      }
    }
  }
}

/*******************************
    INLINE FORM BOX
*******************************/
.inline-form-box {
  max-width: 1100px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  .single-row {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 16px;
    @extend .mx-reverse-2;
    > * {
      padding: 0 8px;
    }
    .label-col {
      width: 300px;
      text-align: right;
      font-size: 15px;
      font-weight: 700;
      margin-top: 3px;
      @include breakpoint(small-laptop) {
        width: 225px;
      }
      @include breakpoint(ipad) {
        width: 100%;
        text-align: left;
        margin-bottom: 4px;
      }
    }
    .field-col {
      max-width: calc(100% - 300px - 270px);
      width: 100%;
      @include breakpoint(small-laptop) {
        max-width: calc(100% - 225px - 200px);
      }
      @include breakpoint(ipad) {
        max-width: calc(100% - 200px);
      }
    }
    .field-btn-col {
      width: 270px;
      @include breakpoint(small-laptop) {
        width: 200px;
      }
    }
  }
  .submission-box {
    margin-top: 48px;
    @include breakpoint(small-laptop) {
      margin-top: 32px;
    }
    .sic-btn {
      margin-right: 285px;
      margin-left: auto;
      display: block;
      min-width: 180px;
      @include breakpoint(small-laptop) {
        min-width: 150px;
        margin-right: 215px;
      }
    }
  }
}

/*******************************
    MAIN CONTENT TITLE BOX
*******************************/
.main-content-title-box {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  .inner1 {
    margin-right: 16px;
    margin-bottom: 16px;
  }
  .inner2 {
    margin-bottom: 16px;
  }
}

/*******************************
    SIC MODAL
*******************************/
.sic-modal {
  .modal-header {
    position: relative;
    padding-right: 50px;
    border-bottom: 0 !important;
    min-height: 75px;
    .btn-close {
      background-color: $secondary2;
      width: 25px;
      height: 25px;
      padding: 2px;
      border-radius: 50%;
      background-image: url("../../images/svgIcons/close-icon.svg");
      opacity: 1;
      background-size: 13px !important;
      position: absolute;
      top: 30px;
      right: 25px;
    }
    .modal-title {
      margin-bottom: 0;
    }
  }
  .modal-footer {
    border-top: 0 !important;
  }
}

/*******************************
    CONTAINER FOR CHECKBOXES
*******************************/
.container-for-checkboxes {
  border: 1px solid #eaedf0;
  padding: 24px 24px 4px;
  border-radius: 6px;
  display: flex;
  flex-wrap: wrap;
  .single-col {
    width: 50%;
    margin-bottom: 20px;
    @include breakpoint(large-laptop) {
      margin-bottom: 12px;
    }
  }
}

/*******************************
    FIELD LIKE BOX
*******************************/
.sic-popover {
  border: 1px solid #919191;
  background-color: $white;
  position: relative;
  border-radius: 6px;
  padding: 1px 2px 1px 8px;
  max-width: 230px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  input {
    border: 0;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    &:focus {
      outline: 0;
    }
  }
  .open-icon {
    cursor: pointer;
    img {
      filter: invert(1);
      max-width: 19px;
      transform: rotate(45deg);
    }
  }
  .toggle-area {
    position: absolute;
    top: calc(100% - 2px);
    left: -1px;
    width: 400px;
    border-radius: 6px;
    padding: 20px 8px 8px;
    box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.12);
    display: none;
    z-index: 1;
    white-space: normal;
    background-color: $white;
    @include breakpoint(large-laptop) {
      width: 300px;
    }
    @include breakpoint(small-laptop) {
      width: 250px;
    }
    .close-icon {
      cursor: pointer;
      position: absolute;
      top: 3px;
      right: 1px;
      img {
        filter: invert(1);
        max-width: 19px;
      }
    }
  }
  &.show-toggle-area {
    .toggle-area {
      top: calc(100% + 2px);
      display: block;
      .description {
        p {
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

/*******************************
    ACCORDION
*******************************/
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition-property: height, visibility;
    transition-duration: 0.35s;
  }
}

.sic-accordion {
  border-radius: 4px !important;
  .accordion-item {
    border: 0 !important;
    border-radius: 4px !important;
    margin-bottom: 20px;
    overflow: hidden;
    .accordion-header {
      .accordion-button {
        font-size: 15px;
        padding: 9px 16px;
        border: 1px solid #e5e9ec;
        border-radius: 4px;
        // width: 1600px;
        &:after {
          background-image: url("../../images/svgIcons/right-arrow.svg");
          background-size: 15px;
          background-position: 8px 2px;
          padding-right: 25px;
        }
        &:not(.collapsed) {
          color: unset !important;
          background-color: unset !important;
          box-shadow: unset !important;
          &::after {
            transform: rotate(90deg);
            // background-position: 3px 5px;
          }
        }
      }
    }
    .accordion-body {
      padding-left: 0;
      padding-right: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

/*******************************
    VERTICAL TABS
*******************************/
.vertical-tabs-container {
  display: flex;
  flex-wrap: wrap;
  border-radius: 8px;
  overflow: hidden;
  .vertical-tabs-container-sidebar {
    background-color: $dark-blue;
    padding: 8px;
    width: 245px;
    ul {
      border: 0;
      li {
        width: 100%;
        margin-bottom: 8px;
        a {
          border: 0;
          color: $white;
          border-radius: 8px 0 0 8px;
          padding: 6px 8px;
          &.active {
            background-color: $secondary2;
            color: $white;
            font-weight: normal;
          }
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  .vertical-tabs-container-content-box {
    width: calc(100% - 245px);
    padding: 16px;
  }
}

/*******************************
    MULTI DATE SELECTOR
*******************************/
.multi-date-selector {
  display: flex;
  align-items: center;
  color: #9da8b4;
  .calendar-box {
    overflow: hidden;
    background-image: url("../../images/svgIcons/calendar.svg");
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
    margin: 0 16px;
    @include breakpoint(large-laptop) {
      background-size: 16px;
      margin: 0 10px;
    }
    input {
      opacity: 0;
      width: 20px;
      height: 20px;
      cursor: pointer;
      &::-webkit-calendar-picker-indicator {
        cursor: pointer;
      }
    }
  }
}

/*******************************
    CHECKBOX ICON
*******************************/
.checkbox-icon {
  width: 20px;
  height: 20px;
  background-color: $success;
  border-radius: 4px;
  position: relative;
  @include breakpoint(large-laptop) {
    width: 17px;
    height: 17px;
  }
  &:before {
    content: "";
    width: 2px;
    height: 6px;
    position: absolute;
    top: 10px;
    left: 5px;
    transform: rotate(-45deg);
    background-color: $white;
    @include breakpoint(large-laptop) {
      top: 8px;
      left: 4px;
    }
  }
  &:after {
    content: "";
    width: 2px;
    height: 12px;
    position: absolute;
    top: 5px;
    left: 10px;
    transform: rotate(45deg);
    background-color: $white;
    @include breakpoint(large-laptop) {
      top: 3px;
      left: 9px;
    }
  }
  &.not-active {
    background-color: #ed081f;
    &:before {
      content: "";
      width: 12px;
      height: 2px;
      position: absolute;
      top: 9px;
      left: 4px;
      transform: rotate(0);
      background-color: $white;
      @include breakpoint(large-laptop) {
        width: 10px;
        top: 7px;
        left: 3px;
      }
    }
    &:after {
      display: none;
    }
  }
  &.deleted {
    background-color: #ed081f;
    &:before {
      content: "";
      width: 12px;
      height: 2px;
      position: absolute;
      top: 9px;
      left: 4px;
      transform: rotate(45deg);
      background-color: $white;
      @include breakpoint(large-laptop) {
        width: 10px;
        top: 7px;
        left: 3px;
      }
    }
    &:after {
      content: "";
      width: 12px;
      height: 2px;
      position: absolute;
      top: 9px;
      left: 4px;
      transform: rotate(-45deg);
      background-color: $white;
      @include breakpoint(large-laptop) {
        width: 10px;
        top: 7px;
        left: 3px;
      }
    }
  }
  &.checkbox-icon-mini {
    width: 16px;
    height: 16px;
    &:before {
      top: 8px;
      left: 4px;
    }
    &:after {
      top: 3px;
      left: 9px;
    }
  }
}

/*******************************
    SCROLLSPY
*******************************/
.sic-scrollspy {
  display: flex;
  flex-wrap: wrap;
  .sic-scrollspy-sidebar {
    width: 250px;
    background-color: $dark-blue;
    border-radius: 8px 0 0 8px;
    overflow: hidden;
    @include breakpoint(large-laptop) {
      width: 200px;
    }
    @include breakpoint(ipad) {
      width: 150px;
    }
    .list-group {
      a {
        background-color: $transparent;
        color: $white;
        border-radius: 0;
        border-color: $dark-blue;
        &.active {
          border-color: $secondary2;
          background-color: $secondary2;
        }
      }
    }
  }
  .sic-scrollspy-content {
    width: calc(100% - 250px);
    padding-left: 24px;
    @include breakpoint(large-laptop) {
      width: calc(100% - 200px);
    }
    @include breakpoint(ipad) {
      width: calc(100% - 150px);
    }
    .scrollspy-item {
      .item-header {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px;
        h5 {
          margin-bottom: 0;
        }
      }
      .item-body {
        margin-bottom: 48px;
      }
    }
  }
}

/*******************************
    DROPDOWN TABS
*******************************/
.dropdown-tabs-head {
  .dropdown-toggle {
    background-color: $secondary2;
    color: $white;
    width: 200px;
    text-align: left;
    border-color: $secondary2;
    &:after {
      float: right;
      margin-top: 11px;
    }
  }
  .nav-tabs {
    display: none;
    max-width: 200px;
    width: 100%;
    &.show {
      display: block;
    }
    li {
      a {
        border-radius: 0;
        &.active,
        &:hover {
          border-color: transparent;
          background-color: $secondary2;
          color: $white;
        }
      }
    }
  }
}

/*******************************
    MAIN FILTER
*******************************/
.main-search-filter-toggle {
  // border: 1px solid #e5e9ec;
  padding: 8px 12px 7px;
  position: relative;
  border-radius: 4px;
  cursor: pointer;
  opacity: 0.5;

  @media screen and (max-width: 2560px) {
    padding: 2px 20px;
  }
  @include breakpoint(large-laptop) {
    padding: 2px 20px;
  }
  @include breakpoint(small-laptop) {
    padding: 2px 20px;
  }
  &:after {
    content: "";
    width: 9px;
    height: 9px;
    background-image: url("../../images/svgIcons/right-arrow.svg");
    display: block;
    position: absolute;
    background-repeat: no-repeat;
    background-position: center;
    top: 10px;
    transform: rotate(90deg);
    left: 12px; //right:8;

    @include transition(0.3s);
    @include breakpoint(large-laptop) {
      top: 10px;
      rotate: 0deg;
    }
  }
  &.active,
  &.collapsed {
    &:after {
      transform: rotate(0deg);
    }
  }
}
.main-search-filter-body {
  padding: 16px 16px 24px;
  background-color: $light-gray;
  border-radius: 8px;
  display: none;
}
.main-search-filter-content-container {
  padding: 8px 16px 24px;
  // background-color: $light-gray;
  border-radius: 8px;
}

/*******************************
    RECTANGULAR RADIO BUTTONS BOX
*******************************/
.rect-radio-btns-box {
  display: flex;
  flex-wrap: wrap;
  .form-check {
    margin-right: 12px;
    label {
      border: 1px solid $secondary2;
      padding: 9px 18px 10px;
      border-radius: 4px;
      margin-left: -28px;
      margin-top: 0;
      background-color: $transparent;
      cursor: pointer;
      @include transition(0.3s);
      @include breakpoint(large-laptop) {
        padding: 8px 18px;
      }
    }
    input {
      opacity: 0;
      &:checked ~ label {
        color: $white;
        background-color: $secondary2;
      }
    }
    &:last-child {
      margin-right: 0;
    }
  }
}

/*******************************
    PROFILE DROPDOWN (USED IN HEADER)
*******************************/
.sic-profile-dropdown {
  border-radius: 0;
  border: 0;
  inset: 23px auto auto 18px !important;
  border-radius: 8px;
  @include box-shadow();
  @include breakpoint(x-large-laptop) {
    inset: 20px auto auto 22px !important;
  }
  .hf-profile-container {
    text-align: center;
    .hf-profile-img {
      margin: 0 auto;
      width: 70px;
      height: 70px;
      overflow: hidden;
      border-radius: 12px;
      margin-bottom: 4px;
      margin-top: 8px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .hf-profile-name {
      font-size: 18px;
    }
  }
  .separator {
    height: 1px;
    background-color: #c9c9c9;
    margin: 4px 24px;
  }
  li {
    a {
      display: block !important;
      text-decoration: none;
      button {
        font-size: 16px;
        color: #7f7f7f;
        padding-left: 50px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        white-space: break-spaces;
        padding: 8px 24px;
        border-left: 4px solid $transparent;
        border-right: 4px solid $transparent;
        background-color: $transparent;
        @include transition(0.3s);
        .icon {
          display: flex;
          align-items: center;
          width: 20px;
          margin-right: 10px;
          i {
            font-size: 20px;
          }
        }
        .text {
          display: block;
          width: calc(100% - 30px);
        }
        &:hover {
          border-left: 4px solid $secondary2;
          background-color: #e4ecf3;
          color: $secondary2;
        }
      }
      &:hover a {
        color: $secondary2;
      }
    }
  }
}

/*******************************
    Profile Picture Uploader
*******************************/
.sic-profile-img-uploader {
  width: 145px;
  width: 145px;
  position: relative;
  .file-upload {
    display: none;
  }
  .circle {
    border-radius: 50%;
    border: 1px solid $gray;
    overflow: hidden;
    width: 145px;
    height: 145px;
    position: relative;
    .profile-pic {
      width: 100%;
      height: 100%;
      display: block;
      object-fit: cover;
      object-position: top center;
      border-radius: 50%;
      transform: scale(1.05);
    }
  }
  .p-image {
    position: absolute;
    top: 72%;
    left: 72%;
    font-size: 12px;
    cursor: pointer;
    color: #000;
    background-color: #fff;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.9);
  }
  .p-image:hover {
    transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
  .upload-button {
    font-size: 1.2em;
  }

  .upload-button:hover {
    transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    color: #999;
  }
}

/*******************************
    Search With Dropdown
*******************************/
.search-with-dropdown {
  position: relative;
  input {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
    padding-right: 2.25rem;
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 16px 12px;
    cursor: pointer;
  }
  .search-dropdown {
    border-radius: 8px;
    position: absolute;
    left: 0;
    top: calc(100% - 10px);
    width: 100%;
    background-color: $white;
    z-index: 1;
    opacity: 0;
    visibility: hidden;
    @extend .box-shadow;
    @include transition(0.1s);
    &.show-dropdown {
      top: 100%;
      opacity: 1;
      visibility: visible;
    }
    ul {
      padding: 8px 0;
      li {
        padding: 4px 16px;
        list-style-type: none;
        font-size: 14px;
        cursor: pointer;
        background-color: $white;
        @include transition(0.3s);
        &:hover {
          background-color: $secondary2;
          color: $white;
        }
      }
    }
  }
}

/*******************************
    Pagination
*******************************/
.sic-pagination {
  li {
    a {
      color: $secondary2;
      &:hover {
        background-color: $secondary2;
        color: $white;
      }
    }
  }
}
.mat-input-element:disabled{
  color: black;
}

