$primary: #000000;
$secondary: #8F6CF6;
$secondary2: #f39c12;
$dark-blue: #18162F;
$dark-blue2: #17335A;
$white: #fff;
$gray: #8B8A9B;
$light-gray: #f5f5f5;
$light-purple: #E3DAFF;
$success: #0ACD82;
$danger: #FF0000;
$orange: #DB8648;
$transparent: rgba(0, 0, 0, 0.0);
$border-color:rgba(185, 189, 189, 0.6);