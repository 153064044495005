$base-color: rgb(160, 152, 152) ;
$disable-background-color: rgb(160, 152, 152) ;

.multiselect-dropdown {
  position: relative;
  width: 100%; 
  font-size: inherit;
  font-family: inherit;

  .dropdown-btn {
    display: inline-block;
    border: 0px solid #e5e9ec !important;
    width: 100%;
    // height: 41px !important;
    font-size: 14px !important;
    font-family: Roboto, "Helvetica Neue", sans-serif !important;
    max-height: 110px;
    overflow-y: auto;
    padding: 6px 12px;
    margin-bottom: 0;
    font-weight: normal;
    line-height: 1.52857143;
    text-align: left;
    vertical-align: middle;
    cursor: pointer;
    background-image: none;
    border-radius: 4px;

    .selected-item {
      border:1px solid #b5b0b0 ;
      // margin-right: 4px;
      margin: 3px 2px !important;
      background: #d1caca  ;
      padding: 2px 8px !important;
      color: #fff !important;
      border-radius: 17px !important;
      float: left;

      a {
    align-items: center;
    color: #17335A !important;
    background: white;
    border-radius: 50%;
    font-size: 15px;
    position: relative;
    left: 6px;
    padding: 0px 8px !important;
    text-align: center;
      }
    }

    .selected-item:hover {
      box-shadow: 1px 1px ;
    }

    .dropdown-down {
      display: inline-block;
      top: 10px;
      width: 0;
      height: 0;
      border-top: 10px solid #adadad;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
    }

    .dropdown-up {
      display: inline-block;
      width: 0;
      height: 0;
      border-bottom: 10px solid #adadad;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
    }
  }

  .disabled {
    &>span {
      background-color: $disable-background-color;
    }
  }
}

.dropdown-list {
  position: absolute;
  padding-top: 6px;
  width: 100%;
  z-index: 9999;
  border: 1px solid #ccc;
  border-radius: 3px;
  background: #fff;
  margin-top: 10px;
  box-shadow: 0px 1px 5px #959595;

  ul {
    padding: 0px;
    list-style: none;
    overflow: auto;
    margin: 0px;
  }

  li {
    padding: 6px 10px;
    cursor: pointer;
    text-align: left;
  }

  .filter-textbox {
    border-bottom: 1px solid #ccc;
    position: relative;
    padding: 10px;

    input {
      border: 0px;
      width: 100%;
      padding: 0px 0px 0px 26px;
    }

    input:focus {
      outline: none;
    }
  }
}

.multiselect-item-checkbox input[type='checkbox'] {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.multiselect-item-checkbox input[type='checkbox']:focus+div:before,
.multiselect-item-checkbox input[type='checkbox']:hover+div:before {
  border-color: $base-color;
  background-color: #f2f2f2;
}

.multiselect-item-checkbox input[type='checkbox']:active+div:before {
  transition-duration: 0s;
}

.multiselect-item-checkbox input[type='checkbox']+div {
  position: relative;
  padding-left: 2em;
  vertical-align: middle;
  user-select: none;
  cursor: pointer;
  margin: 0px;
  color: #000;
}

.multiselect-item-checkbox input[type='checkbox']+div:before {
  box-sizing: content-box;
  content: '';
  color: #f39c12 !important;
  position: absolute;
  top: 50%;
  border-radius: 25%;
  left: 0;
  width: 14px;
  height: 14px;
  margin-top: -9px;
  border: 2px solid #f39c12 !important;
  text-align: center;
  transition: all 0.4s ease;
}

.multiselect-item-checkbox input[type='checkbox']+div:after {
  box-sizing: content-box;
  content: '';
  background-color: #f39c12 !important;
  position: absolute;
  top: 50%;
  left: 4px;
  width: 10px;
  height: 10px;
  margin-top: -5px;
  transform: scale(0);
  transform-origin: 50%;
  transition: transform 200ms ease-out;
}

.multiselect-item-checkbox input[type='checkbox']:disabled+div:before {
  border-color: #cccccc;
}

.multiselect-item-checkbox input[type='checkbox']:disabled:focus+div:before .multiselect-item-checkbox input[type='checkbox']:disabled:hover+div:before {
  background-color: inherit;
}

.multiselect-item-checkbox input[type='checkbox']:disabled:checked+div:before {
  background-color: #cccccc;
}

.multiselect-item-checkbox input[type='checkbox']+div:after {
  background-color: transparent;
  top: 50%;
  left: 4px;
  width: 8px;
  height: 3px;
  margin-top: -4px;
  border-style: solid;
  border-color: #ffffff;
  border-width: 0 0 3px 3px;
  border-image: none;
  transform: rotate(-45deg) scale(0);
}

.multiselect-item-checkbox input[type='checkbox']:checked+div:after {
  content: '';
  transform: rotate(-45deg) scale(1);
  transition: transform 200ms ease-out;
}

.multiselect-item-checkbox input[type='checkbox']:checked+div:before {
  animation: borderscale 200ms ease-in;
  background: #f39c12 !important;
}

.multiselect-item-checkbox input[type='checkbox']:checked+div:after {
  transform: rotate(-45deg) scale(1);
}

@keyframes borderscale {
  50% {
    box-shadow: 0 0 0 2px $base-color;
  }
}
.multiselect-item-checkbox:hover {
  background-color: #daefff !important;
}

.multiselect-dropdown .dropdown-btn::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.multiselect-dropdown .dropdown-btn::-webkit-scrollbar-track {
  background-color: #f5f5f5;
  border-radius: 4px;
}

.multiselect-dropdown .dropdown-btn::-webkit-scrollbar-thumb {
  background-color: #f39c12;
  border-radius: 4px;
}

.multiselect-dropdown .dropdown-btn::-webkit-scrollbar-thumb:hover {
  background: #17335A; 
}

.multiselect-dropdown .dropdown-btn .dropdown-multiselect__caret:before {
  
  color: rgba(0, 0, 0, 0.54);
  border-width: 5px 5px 0 !important;
  border-color: rgba(0, 0, 0, 0.54) transparent;
  content: "";
}