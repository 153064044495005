@font-face {
    font-family: 'Larsseit';
    src: url('../../fonts/Larsseit.otf');
}
@font-face {
    font-family: 'Larsseit-Medium';
    src: url('../../fonts/Larsseit-Medium.otf');
}
@font-face {
    font-family: 'Larsseit-Bold';
    src: url('../../fonts/Larsseit-Bold.otf');
}

html{
    scroll-behavior: smooth;
}
*{
    box-sizing: border-box;
}
body{
    color: $primary;
    margin: 0;
    padding: 0;
    font-size: 14px;
    min-height: 100vh;
    font-family: 'Larsseit';
    background-color: #f8f6f8;
    // @include breakpoint(large-laptop){
    //     font-size: 13px;
    // }
    // @include breakpoint(small-laptop){
    //     font-size: 12px;
    // }
}
h1{
    // font-size: 35px;
    // font-weight: 700;
    max-width: fit-content;
    @include breakpoint(large-laptop){
        font-size: 30px;
    }
    @include breakpoint(small-laptop){
        font-size: 26px;
    }
}
h2{
    // font-size: 30px;
    // font-weight: 600;
    max-width: fit-content;
    @include breakpoint(large-laptop){
        font-size: 26px;
    }
    @include breakpoint(small-laptop){
        font-size: 24px;
    }
}
h3{
    // font-size: 28px;
    // font-weight: 600;
    max-width: fit-content;
    @include breakpoint(large-laptop){
        font-size: 24px;
    }
    @include breakpoint(small-laptop){
        font-size: 20px;
    }
}
h4{
    // font-size: 26px;
    // font-weight: 600;
    max-width: fit-content;
    @include breakpoint(large-laptop){
        font-size: 20px;
    }
    @include breakpoint(small-laptop){
        font-size: 18px;
    }
}
h5{
    // font-size: 22px;
    // font-weight: 600;
    max-width: fit-content;
    @include breakpoint(large-laptop){
        font-size: 18px;
    }
    @include breakpoint(small-laptop){
        font-size: 16px;
    }
}
h6{
    // font-size: 20px;
    // font-weight: 600;
    max-width: fit-content;
    @include breakpoint(large-laptop){
        font-size: 16px;
    }
    @include breakpoint(small-laptop){
        font-size: 14px;
    }
}
ul,
ol{
    padding: 0;
    margin: 0;
}
img{
    max-width: 100%;
}
span{
    display: inline-block;
}
a,
button{
    transition: .3s;
}
.mat-form-field-flex{
    max-height: 41px;
}
.flex-col {
    flex-direction: column;
    margin-top: 6px;
}
.ngx-pagination li {
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    margin-right: .0625rem;
    border-radius: 0;
    border: 1px solid #dee2e6;
}
.mat-pseudo-checkbox-checked, .mat-pseudo-checkbox-indeterminate, .mat-accent .mat-pseudo-checkbox-checked, .mat-accent .mat-pseudo-checkbox-indeterminate {
    background: #f39c12;
}
.cdk-global-overlay-wrapper, .cdk-overlay-container {
    z-index: 10000 !important;
    }