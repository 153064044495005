.bg-cover-center {
  @include bg-cover-center();
}
.flex-center {
  @include flexCenter();
}
.no-decoration {
  text-decoration: none;
}
.min-h-100vh {
  min-height: 100vh;
}
.no-list-style {
  list-style-type: none;
}
.box-shadow {
  @include box-shadow();
}
.negative-horizontal-margin {
  margin-left: -15px;
  margin-right: -15px;
}
.overflow-hidden {
  overflow: hidden;
}
.table-layout-fixed {
  table-layout: fixed;
}
.cursor-pointer {
  cursor: pointer;
}
.line-clamp1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.w-fit-content {
  width: fit-content;
}
.text-mini {
  font-size: 14px;
  font-weight: 700;
}
.text-large {
  font-size: 20px;
}
.mx-reverse-2 {
  margin-left: -8px;
  margin-right: -8px;
}
.stylish-scroll {
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  &::-webkit-scrollbar-track {
    background-color: $light-gray;
    border-radius: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $secondary2;
    border-radius: 4px;
  }
}
.w-75 {
  width: 65% !important;
}
.form-control-mini {
  padding: 3px 8px 1px;
  border-radius: 6px;
  border: 1px solid #858585;
  @include transition(0.3s);
  width: 100%;
  &:focus {
    outline: 0;
    border-color: $secondary2;
  }
}
.form-control-mini-freez {
  padding: 2px 8px;
  border-radius: 6px;
  border: 1px solid #858585;
  max-width: 160px;
  width: 100%;
}
.max-w-500px {
  max-width: 500px;
  width: 100%;
}
.field-with-inline-label {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  // width: 400px;
  span {
    // width: 100px;
    margin-right: 8px;
    color: #9da8b4;
  }

 
}
/***************************************
    TEXT COLORS
***************************************/
.text-primary {
  color: $primary !important;
}
.text-secondary {
  color: $secondary2 !important;
}
.text-gray {
  color: $gray !important;
}
.text-light-gray {
  color: $light-gray !important;
}
.text-success {
  color: $success !important;
}
.text-danger {
  color: $danger !important;
}

/***************************************
    BACKGROUND COLORS
***************************************/
.bg-primary {
  background-color: $primary !important;
}
.bg-secondary {
  background-color: $secondary2 !important;
}
.bg-gray {
  background-color: $gray !important;
}
.bg-lightgray {
  background-color: $light-gray !important;
}
.bg-success {
  background-color: $success !important;
}
.bg-danger {
  background-color: $danger !important;
}
.table-actions{
  text-align: center;
}

.sic-table th,
.sic-table td {
  padding: 8px;
  // border: 1px solid #dddddd;
  text-align: left;
}
@media screen and (max-width: 600px) {
  .sic-table th,
  .sic-table td {
    display: block;
    width: 100%;
  }

  .sic-table th {
    text-align: center;
  }
}
.table-status {
  text-align: center;
}
thead {
  position: sticky;
  top: 0;
  z-index: 100;
}


.advance{
  border-radius: 10px;
}
.modal-header{
  margin-bottom: -15px;
}
.sic-modal .modal-header{
  padding-left: 15px;
}
.modal.show .modal-dialog {
  transform: none;
  // position: absolute;
  // right: 0;
  // top: 95px;
}
.modal-footer{
  justify-content: center;
  position: unset;
}
.modal-content{
  // width: 70%;
}
.sic-btn{
  padding: 4px 15px 4px;
}
.dropdown {
  float: right;
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  z-index: 200;
  background-color: white;
  width: 435%;
  right: 0px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 18px 50px -10px;
  padding: 0px 16px 16px 16px;
}
@media (min-width: 1024px) and (max-width: 1440px) {
.dropdown-content{
  height: 73vh;
  overflow-y: scroll;
}
}
.dropdown-content a {
  color: black;
  // padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {background-color: #ddd;}

.show {
  display:block;
}

.table-container,.centered-container{
  height: 73vh; 
  overflow-y: auto; 
}

@media (min-width: 1024px) and (max-width: 1440px) {
  .table-container, .centered-container{
    height: 75vh; 
  }
}

@media (min-width: 1440px) and (max-width: 1920px) {
  .table-container,.centered-container{
    height: 78vh; 
  }
}

@media (min-width: 1920px) and (max-width: 2560px) {
  .table-container, .centered-container{
    height: 82vh;
  }
}

@media (min-width: 2560px) {
  .table-container,.centered-container {
    height: 85vh;  
  }
}

.table-container::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.table-container::-webkit-scrollbar-track {
  background-color: #f5f5f5;
  border-radius: 4px;
}

.table-container::-webkit-scrollbar-thumb {
  background-color: #f39c12;
  border-radius: 4px;
}

.table-container::-webkit-scrollbar-thumb:hover {
  background: #17335A; 
}

.modal-body::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.modal-body::-webkit-scrollbar-track {
  background-color: #f5f5f5;
  border-radius: 4px;
}

.modal-body::-webkit-scrollbar-thumb {
  background-color: #f39c12;
  border-radius: 4px;
}

.modal-body::-webkit-scrollbar-thumb:hover {
  background: #17335A; 
}
.mat-select-value {
  display: flex;
  align-items: center; 
}
.advanced-filter-row .single-col .filter-with-title .inner1{
  font-weight: 700;
}
input{
  font-size: 14px !important;
  font-family: Roboto, "Helvetica Neue", sans-serif !important;
}
.dropdown-content::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.dropdown-content::-webkit-scrollbar-track {
  background-color: #f5f5f5;
  border-radius: 4px;
}

.dropdown-content::-webkit-scrollbar-thumb {
  background-color: #f39c12;
  border-radius: 4px;
}

.dropdown-content::-webkit-scrollbar-thumb:hover {
  background: #17335A;
}
.bdr {
  border: 1px solid #e5e9ec;
  border-radius: 4px;
}