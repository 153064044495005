// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$skote-primary: mat.define-palette(mat.$indigo-palette);
$skote-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$skote-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$skote-theme: mat.define-light-theme(
  (
    color: (
      primary: $skote-primary,
      accent: $skote-accent,
      warn: $skote-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($skote-theme);

@import "./assets/sass/partials/variables";
@import "./assets/sass/partials/mixins";
@import "./assets/sass/partials/breakpoints";
@import "./assets/sass/base/utilities";
@import "./assets/sass/base/global";
@import "./assets/sass/components/ui-elements";
@import "~@ng-select/ng-select/themes/material.theme.css";
@import "ngx-toastr/toastr";
/***************************************
    HEADER
***************************************/
header {
  padding-left: 24px;
  background-color: $white;
  .header-top {
      padding: 5px 16px 5px 0px;
      border-bottom: 3px solid #f5f5f5;
      box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px;
    @include breakpoint(large-laptop) {
      padding: 8px 16px 8px 0;
    }
    .user-info-box {
      display: flex;
      align-items: center;
      width: fit-content;
      margin-left: auto;
      .user-name {
        font-size: 18px;
        @include breakpoint(large-laptop) {
          font-size: 16px;
        }
      }
      .user-avatar {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        overflow: hidden;
        margin-left: 8px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }
      .sic-profile-dropdown {
        transform: unset !important;
        top: 67px !important;
        right: 10px !important;
        left: unset !important;
        bottom: unset !important;

        &:after {
          content: "";
          width: 26px;
          height: 26px;
          border-bottom: 13px solid #fff;
          border-right: 13px solid transparent;
          border-left: 13px solid transparent;
          border-top: 13px solid transparent;
          position: absolute;
          top: -25px;
          right: 10px;
        }
      }
    }
  }
  .header-bottom {
    padding: 8px 16px 0 0;
  }
}

/***************************************
    MAIN CSS
***************************************/

/* initial screens */
.initial-screen {
  padding: 0;
  margin-left: -240px;
  .initial-screen-layer {
    background-color: rgba(24, 52, 91, 0.8);
    .container {
      .row {
        min-height: 100vh;
        .form-box {
          @include breakpoint(mobile) {
            margin-left: 20px;
          }
          form {
            display: flex;
            flex-wrap: wrap;
            align-content: space-between;
            position: relative;
            background-color: $white;
            border-radius: 10px;
            padding: 110px 60px 60px;
            max-width: 450px;
            min-height: 500px;
            margin: auto;
            > * {
              width: 100%;
            }
            .back-btn,
            .cross-btn {
              width: 55px;
              height: 55px;
              border-radius: 50%;
              border: 0;
              padding: 5px;
              position: absolute;
              top: 16px;
              left: 16px;
              background-color: $secondary2;
              @include transition(0.3s);
              @include flexCenter();
              img {
                width: 20px;
              }

              &:hover {
                background-color: #18162f;
              }
            }
            .cross-btn {
              left: unset;
              right: 16px;
              img {
                width: 40px;
              }
            }
            .input-group {
              .input-group-text {
                padding-left: 20px;
                border-radius: 10px 0px 0px 10px;
                img {
                  max-height: 35px;
                }
              }
              input {
                height: 60px !important;
                border-radius: 0px 10px 10px 0px;
                font-size: 18px;
              }
              input ~ .input-group-addon {
                top: 18px;
                z-index: 9;
              }
            }
            .sic-btn {
              padding: 16px 18px 15px;
              font-size: 18px;
              font-weight: 700;
            }
            .tick-circle {
              width: 110px;
              height: 110px;
              border-radius: 50%;
              padding: 10px;
              background-color: $secondary2;
              margin: 0 auto;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
          .bottom-fixed-message {
            position: absolute;
            bottom: 20px;
            left: 0;
            right: 0;
          }

          &.login-form-box {
            form {
              padding-top: 40px;
            }
          }
        }
      }
    }
  }
}
/* initial screens end */

.main-inner {
  min-height: 100vh;
  display: flex;
  flex-wrap: wrap;
}

/* Sidebar */
.sidebar {
  width: 240px;
  background-color: $dark-blue2;
  padding-bottom: 16px;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  @include transition(0.3s);
  @include breakpoint(large-laptop) {
    width: 220px;
  }
  @include breakpoint(small-laptop) {
    width: 200px;
  }
  .sidebar-taggle-btn {
    background-color: $white;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.9);
    width: 28px;
    height: 28px;
    border-radius: 50%;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: -14px;
    top: 22px;
    cursor: pointer;
    z-index: 1;
    @include transition(0.3s);
    @include breakpoint(large-laptop) {
      width: 24px;
      height: 24px;
      right: -12px;
    }
    i {
      @include transition(0.3s);
    }
    &:hover {
      background-color: $secondary2;
      border: 1px solid $secondary2;
      i {
        color: $white;
      }
    }
  }
  .logo-box {
    position: relative;
    margin-bottom: 12px;
    padding: 16px 28px 16px 16px;
    min-height: 71px;
    &:after {
      content: "";
      height: 1px;
      background-color: #ffffff24;
      background: radial-gradient(
        circle,
        rgba(255, 255, 255, 0.4990371148459384) 0%,
        rgba(23, 51, 90, 1) 100%
      );
      position: absolute;
      left: 16px;
      right: 16px;
      bottom: 0;
    }
  }
  .nav-box {
    padding: 0 16px 0 20px;
    max-height: 80vh;
    overflow-y: auto;
    overflow-x: hidden;
    margin-right: 4px;
    @extend .stylish-scroll;
    @include breakpoint(large-laptop) {
      padding: 0 8px 0 16px;
    }
    nav {
      ul {
        li {
          list-style: none;
          a {
            transition: 0.3s;
            display: block;
            text-decoration: none;
            color: $gray;
            &:hover,
            &.active {
              color: #fff;
              img {
                filter: brightness(10);
              }
            }
          }
        }
      }
      > ul {
        > li {
          padding: 12px 0;
          > a {
            text-decoration: none;
            display: flex;
            flex-wrap: wrap;
            width: 199px;
            @include breakpoint(large-laptop) {
              width: 180px;
            }
            @include breakpoint(small-laptop) {
              width: 172px;
            }
            img {
              margin-right: 16px;
              width: 24px;
              transition: 0.2s;
              @include breakpoint(large-laptop) {
                width: 18px;
                margin-right: 12px;
              }
            }
            span {
              display: block;
              width: calc(100% - 40px);
              line-height: 22px;
              padding-top: 2px;
            }
          }
        }
      }
      .has-dropdown {
        > a {
          position: relative;
          padding-right: 16px;
          &::after {
            top: 1px;
            content: "\f107";
            font-family: "fontAwesome";
            position: absolute;
            right: 0px;
            font-size: 16px;
          }
        }
        .dropdown-list1 {
          margin: 10px 0 0 10px;
          border-left: 1px solid $gray;
          > li {
            > a {
              padding: 4px 0 4px 30px;
              position: relative;
              width: 184px;
              &:before {
                content: "";
                width: 6px;
                height: 6px;
                border-radius: 50%;
                background-color: $gray;
                position: absolute;
                top: 13px;
                left: 10px;
                @include transition(0.3s);
              }
              &:hover,
              &.active {
                &:before {
                  background-color: $white;
                }
              }
            }

            &:first-child {
              a {
                // padding-top: 0;
                &:before {
                  top: 13px;
                }
              }
            }
            &:last-child {
              a {
                padding-bottom: 0;
                &:before {
                  top: 13px;
                }
              }
            }
          }
        }
      }
    }
  }
}

.main-content-box {
  width: calc(100% - 240px);
  margin-left: auto;

  @include transition(0.3s);
  @include breakpoint(large-laptop) {
    width: calc(100% - 220px);
  }
  @include breakpoint(small-laptop) {
    width: calc(100% - 200px);
  }
  .main-content {
    padding: 0px 16px;
    .main-content-inner {
      background-color: $white;
      border-radius: 8px;
      overflow: hidden;
      padding: 0px 16px;
    }
    .dashboard-filters {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-end;
      @include breakpoint(ipad) {
        justify-content: center;
        height: 550px;
      }
      .rect-radio-btns-box {
        justify-content: center;
        @include breakpoint(ipad) {
          width: 100%;
          margin-bottom: 16px;
        }
      }
    }
  }
}

.sidebar-toggle-btn-clicked {
  .sidebar {
    width: 64px;
    @include breakpoint(large-laptop) {
      width: 50px;
    }
    .sidebar-taggle-btn {
      i {
        transform: rotate(180deg);
      }
    }
    .logo-box {
      img {
        display: none;
      }
    }
    .nav-box nav {
      > ul {
        > li {
          a {
            span {
              // display: none;
            }
          }
        }
      }
    }
  }
  .main-content-box {
    width: calc(100% - 64px);
  }
}

.filter-container {
  display: flex;
  align-items: center;
  justify-content: start;
  margin-bottom: 2px;
  .inner1,
  .inner2 {
    margin-bottom: 8px;
  }
  .inner1 {
    width: 467vw ;
    @include breakpoint(desktop) {
      // width: calc(100% - 250px);
      // width: calc(100% - 0);
    }
  }
  .inner2 {
    width: 291px;
    @include breakpoint(desktop) {
      width: 250px;
    }
  }
}

div#add-new-user,
#generate-whmcs-invoice {
  .inline-form-box {
    .single-row {
      .label-col,
      .field-btn-col {
        width: 200px;
        @include breakpoint(ipad) {
          width: 100%;
        }
      }
      .field-col {
        max-width: calc(100% - 400px);
        @include breakpoint(ipad) {
          max-width: 100%;
        }
      }
    }
  }
  .modal-footer {
    button {
      margin-right: 200px;
      @include breakpoint(ipad) {
        margin-right: 0;
      }
    }
  }
}

.sic-table {
  .form-control-mini {
    max-width: 150px;
  }
}

.advanced-filter-row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -4px;
  margin-right: -4px;
  margin-top: -16px;
  margin-bottom: 16px;
  font-size: 14px;
  .single-col {
    padding: 0 8px;
    margin-top: 16px;
    .filter-with-title {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      .inner1 {
        padding-right: 8px;
        width: 110px;
      }
      .inner2 {
        width: calc(100% - 110px);
        .dropdown,
        .dropdown > button,
        .sic-btn-secondary {
          width: 100%;
        }
        .mega-dropdown {
          // width: 50vw;
          li {
            padding: 8px 16px;
          }
          .radio-btn-list,
          .checkbox-list {
            display: flex;
            flex-wrap: wrap;
          }
          .radio-btn-list {
            .radio-btn-list-col {
              width: 25%;
              padding: 0 8px;
            }
          }
          .checkbox-list {
            .checkbox-list-col {
              width: 20%;
              padding: 0 8px;
            }
          }
        }
      }
      &.no-title {
        .inner2 {
          width: 100%;
        }
      }
    }
  }
  &.row5col {
    .single-col {
      width: 20%;
      @include breakpoint(large-laptop) {
        width: 33.33%;
      }
      @include breakpoint(ipad) {
        width: 100%;
      }
    }
  }
  &.row4col {
    .single-col {
      width: 25%;
      @include breakpoint(large-laptop) {
        width: 50%;
      }
      @include breakpoint(ipad) {
        width: 100%;
      }
    }
  }
  .btn-group {
    width: 100%;
    button {
      text-align: left;
      background-color: white;
      color: inherit;
      border-color: #ebeef1;
    }
    button.dropdown-toggle {
      max-width: 40px;
    }
    .dropdown-menu {
      transform: translate(0, 40px) !important;
      width: 100%;
      li {
        padding: 4px 16px;
      }
    }
  }
}

.sic-scrollspy {
  .report-listing-content {
    position: relative;
    height: calc(100vh - 230px);
    overflow: auto;
  }
}

#generate-invoice {
  .modal-body {
    min-height: 300px;
    .logo-box {
      text-align: center;
      img {
        filter: brightness(0);
        max-width: 220px;
      }
    }
    .org-reports-dropdown-col,
    .prov-reports-dropdown-col {
      display: none;
      min-height: 38px;
      &.active {
        display: block;
      }
    }
    .form-check {
      width: 50%;
      .form-check-input {
        opacity: 0;
      }
      label {
        background-color: $white;
        margin-left: -24px;
        padding: 10px;
        margin-top: 0px;
        width: calc(100% + 24px);
        text-align: center;
        border: 1px solid $secondary2;
        cursor: pointer;
        border-radius: 4px;
        @include transition(0.3s);
      }
      .form-check-input:checked ~ label {
        background-color: $secondary2;
        color: $white;
      }
      &:first-child {
        label {
          border-radius: 4px 0px 0px 4px;
        }
      }
      &:last-child {
        label {
          border-radius: 0px 4px 4px 0px;
          border-left: 0;
          @include breakpoint(large-laptop) {
            border-left: 1px solid $secondary2;
          }
        }
      }
    }
    .btn-group {
      width: 100%;
      button {
        width: calc(100% - 50px);
        text-align: left;
      }
      button.dropdown-toggle {
        width: 50px;
        text-align: center;
      }
      .dropdown-menu {
        transform: translate3d(0px, 38px, 0px) !important;
        width: 100%;
        li {
          a {
            text-decoration: none;
            padding: 8px;
            color: inherit;
            display: block;
            &:hover {
              background-color: $light-gray;
            }
          }
        }
      }
    }
  }
}

.dashboard-main-chart-area {
  display: flex;
  flex-wrap: wrap;
  @extend .negative-horizontal-margin;
  > div {
    padding-left: 16px;
    padding-right: 16px;
  }
  .chart-box {
    width: calc(100% - 340px);
    @include breakpoint(laptop) {
      width: 66.66%;
    }
    @include breakpoint(ipad) {
      width: 100%;
    }
  }
  .chart-sidebar {
    width: 340px;
    @include breakpoint(laptop) {
      width: 33.33%;
    }
    @include breakpoint(ipad) {
      width: 100%;
      margin-top: 24px;
    }
    img.total-sps-chart {
      width: 100%;
      display: block;
      margin: auto;
      @include breakpoint(large-laptop) {
        width: 94%;
      }
      @include breakpoint(laptop) {
        width: 65%;
      }
    }
  }
}
.dashboard-small-chart-box {
  min-height: 375px;
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  @include breakpoint(large-laptop) {
    min-height: 275px;
  }
  @include breakpoint(small-laptop) {
    min-height: 255px;
  }
  > * {
    width: 100%;
  }
  &.dashboard-small-chart-box1 {
    img {
      max-width: 220px;
      width: 100%;
      @include breakpoint(large-laptop) {
        max-width: 160px;
      }
    }
  }
  &.dashboard-small-chart-box2 {
    img {
      max-width: 400px;
      width: 100%;
      @include breakpoint(large-laptop) {
        max-width: 300px;
      }
    }
  }
  &.dashboard-small-chart-box3 {
    img {
      max-width: 100%;
      width: 100%;
    }
  }
}

.expandable-row-toggle {
  cursor: pointer;
  &:hover,
  &.active {
    td {
      background-color: $light-gray;
    }
  }
}
.expandable-row {
  display: none;
}
.calls-actions-box {
  a {
    width: 40px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    padding: 1px;
    color: #fff;
    text-decoration: none;
    margin-bottom: 4px;
    border-radius: 2px;
    margin-left: auto;
    width: 38px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.cpr-table-top {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 24px;
  .inner1 {
    display: flex;
    flex-wrap: wrap;
    width: calc(100% - 250px);
    @include breakpoint(large-laptop) {
      width: calc(100% - 210px);
    }
    .form-check {
      margin-right: 16px;
    }
  }
  .inner2 {
    width: 250px;
    @include breakpoint(large-laptop) {
      width: 210px;
    }
  }
}

.organization-dashboard-main-chart-area {
  display: flex;
  flex-wrap: wrap;
  margin-left: -8px;
  margin-right: -8px;
  > * {
    padding-left: 8px;
    padding-right: 8px;
  }
  .inner1 {
    width: calc(100% - 368px);
    @include breakpoint(large-laptop) {
      width: calc(100% - 330px);
    }
    @include breakpoint(large-laptop) {
      width: calc(100% - 270px);
    }
    @include breakpoint(desktop) {
      width: 100%;
      border-bottom: 0 !important;
    }
  }
  .inner2 {
    width: 368px;
    @include breakpoint(large-laptop) {
      width: 330px;
    }
    @include breakpoint(large-laptop) {
      width: 270px;
    }
    @include breakpoint(desktop) {
      width: 100%;
      margin-top: 16px;
    }
  }
}

.sic-modal {
  .modal-lg {
    .inline-form-box {
      .label-col {
        width: 200px;
      }
      .field-col {
        max-width: calc(100% - 200px);
        width: 100%;
      }
    }
  }
}

.main-content-title-box {
  .field-with-inline-label {
    @include breakpoint(large-laptop) {
      width: 315px;
    }
    @include breakpoint(small-laptop) {
      width: 280px;
    }
  }
}
.super-admin-dashboard-small-chart-box {
  min-height: 484px;
  @media (max-width: 1399px) {
    min-height: 350px;
  }
}
.main-breadcrumb {
  padding: 12px 16px 4px 25px;
  background: $white;
}

/***************************************
    FOOTER
***************************************/
/********
zubair
*******/
/**************************************
pagination setting
**************************************/
li.page-item {
  &:nth-of-type(1n + 5) {
    display: none;
  }
  &:last-child {
    display: block;
  }
}

// .page-item-1 {
//     &:first-child .page-link-1 {
//         border-radius: 50%;
//         border: 1px solid $border-color;
//         padding: 5px 15px;
//     }
//     &:last-child .page-link-1 {
//         border-radius: 50%;
//         border: 1px solid $border-color;
//         padding: 5px 15px;
//     }
//   }
.Profile-content {
  p {
    color: $gray;
  }
  .Name {
    @include breakpoint(small-laptop) {
      font-size: 15px;
    }
  }
}
.setting-icon {
  i.fa.fa-cog {
    cursor: pointer;
    font-size: large;
    &:hover {
      color: $secondary2;
    }
  }
}
.profile-img {
  img {
    width: 55px !important;
    height: 55px !important;
  }
}
.dropdown-toggle {
  &::after {
    display: none !important;
  }
}
.dashboard-filters {
  .select-days {
    width: 100%;
  }
}

ul,
#myUL {
  margin: 0;
  padding: 0;
  list-style-type: none;
  font-size: 15px;
  .caret {
    cursor: pointer;
    -webkit-user-select: none; /* Safari 3.1+ */
    -moz-user-select: none; /* Firefox 2+ */
    -ms-user-select: none; /* IE 10+ */
    user-select: none;
    &::before {
      content: "\f105";
      color: black;
      display: inline-block;
      margin-right: 6px;
      font-family: "FontAwesome";
      font-size: 18px;
    }
    #icon1 {
      color: #f39c12;
    }
  }
  .caret-down::before {
    transform: rotate(90deg);
  }
  .nested {
    display: none;
  }
  .active_1 {
    display: block;
    padding-left: 25px;
  }
}
//   ul, #myUL {
//     list-style-type: none;
//   }

//   #myUL {
//     margin: 0;
//     padding: 0;
//   }

//   .caret {
//     cursor: pointer;
//     -webkit-user-select: none; /* Safari 3.1+ */
//     -moz-user-select: none; /* Firefox 2+ */
//     -ms-user-select: none; /* IE 10+ */
//     user-select: none;
//   }

//   .caret::before {
//     content: "\f105";
//     color: black;
//     display: inline-block;
//     margin-right: 6px;
//     font-family: 'FontAwesome';
//   }

//   .nested {
//     display: none;
//   }
//   .active{
//     display: block;
//     padding-left: 10px;
//   }

.fa-folder::before {
  font-family: "FontAwesome";
  padding: 0 10px;
}
.fa-folder-open::before {
  font-family: "FontAwesome";
  padding: 0 10px;
}
//   #icon1{
//       color: yellow;
//   }

.flex {
  display: flex;
}
.flex-col {
  flex-direction: column;
}
.flex-row {
  flex-direction: row;
}
.item-center {
  align-items: center;
}
.justify-center {
  justify-content: center;
}
.gap-2 {
  gap: 1rem;
}
.gap-3 {
  gap: 1.5rem;
}
.gap-4 {
  gap: 2rem;
}
.flex-wrap {
  flex-wrap: wrap;
}

img.w-5 {
  width: 1.3rem;
}
.breadcrumb {
  background-color: transparent;
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: var(--bs-font-sans-serif);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
}

.bold {
  font-weight: bold;
}
.cdr_dropdown_arrow::after {
  content: "";
  display: inline-block !important;
  margin-left: 0.255em !important;
  vertical-align: 0.255em !important;
  border-bottom: 0 !important;
  border-left: 0.3em solid transparent !important;
  border-top: 0.3em solid !important;
  border-right: 0.3em solid transparent !important;
}
.selected-item-container {
  .selected-item {
    display: inherit !important;
  }
}
.multiselect-dropdown {
  .dropdown-btn {
    border: 1px solid #ebeef1; //continue tommorow
  }
  .dropdown-list1 {
    .item2 {
      max-height: 145px !important;
    }
  }
}
.form-inline.row-item.ng-star-inserted {
  display: flex;
}
.paddingBottom {
  padding-bottom: 0 !important;
}
.paddingTop {
  padding-top: 0 !important;
}
.text-error {
  color: red;
}
.ngx-pagination .current {
  background-color: #f39c12 !important;
  color: white !important;
}
.align-arrow-right {
  position: absolute;
  right: 0;
  top: 0;
  color: rgba(0, 0, 0, 0.54);
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.filter_button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  border: 0;
  background: transparent;
  width: 100%;
  text-align: center;
  width: 7rem;
}
.selected_filter_color {
  color: red;
  outline: none;
}
.daterangepicker .ranges li.active {
  background-color: #f39c12;
  color: #fff;
}
.daterangepicker td.in-range {
  background-color: #f2dcb0;
  border-color: transparent;
  color: #000;
  border-radius: 0;
}
.daterangepicker td.active, .daterangepicker td.active:hover {
  background-color: #f39c12;
  border-color: transparent;
  color: #fff;
}
.cdk-global-overlay-wrapper, .cdk-overlay-container {
  z-index: 10000 !important;
  }
@import '~bootstrap/dist/css/bootstrap.min.css';
@import '~bootstrap-daterangepicker/daterangepicker.css';
