@mixin bg-cover-center{
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

@mixin transition($val){
    transition: $val;
}

@mixin transitionEaseIn($val) {
    transition: $val;
    transition-timing-function: ease-in;
}

@mixin blackTransparent($val) {
    background-color: rgba(0, 0, 0, $val);
}

@mixin flexCenter(){
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}
@mixin flexCenter_1(){
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: start;
}

@mixin boxShadowBottom($val1, $val2, $val3, $colorTransparency){
    box-shadow: $val1 $val2 $val3 rgba(0, 0, 0, $colorTransparency);
}

@mixin box-shadow(){
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}