/*************************************
    FORM CONTROL FIELD
*************************************/
.container {
  @media only screen and (min-width: 1600px) {
    max-width: 1530px;
  }
}
.form-control {
  @include transitionEaseIn(0.3s);
  &:focus {
    box-shadow: none;
    border: 1px solid $secondary2;
  }
  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  /* Firefox */
  &[type="number"] {
    -moz-appearance: textfield;
  }
}
.form-floating {
  > label {
    padding: 0.25rem 0.75rem;
    font-size: 16px;
    color: #999999;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    height: unset;
    top: 13px;
  }

  > .form-select {
    padding-top: 1.425rem;
    padding-bottom: 0.425rem;
    cursor: pointer;
  }
}
.form-control,
.form-select {
  background-color: $white;
  height: 41px !important;
  font-size: 14px;
  border-color: #e5e9ec;
  @include transitionEaseIn(0.3s);
  @include breakpoint(large-laptop) {
    height: 33px !important;
  }
  &:focus {
    box-shadow: none;
    border: 1px solid $secondary2;
  }
}
.form-floating {
  .form-control,
  .form-select {
    padding: 1.25rem 0.75rem 0.5rem !important;
  }
}
textarea.form-control {
  height: unset !important;
}
.form-control {
  padding: 0.6rem 0.75rem !important;
  @include breakpoint(large-laptop) {
    padding: 0.3rem 0.75rem !important;
  }
  @include transitionEaseIn(0.3s);
  &:focus {
    box-shadow: none;
    border: 1px solid $secondary2;
  }
  &[type="password"] {
    padding-right: 2.5rem !important;
  }
  & ~ .input-group-addon {
    position: absolute;
    right: 15px;
    top: 15px;
    i {
      color: #5f6368;
    }
  }
}
.form-select {
  cursor: pointer;
}

.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  opacity: 1;
}
.form-floating > .form-control:not(:placeholder-shown),
.form-floating > .form-select:not(:placeholder-shown) {
  background-color: $white;
  border-color: $primary;
}

.form-check {
  text-align: left;
  label {
    line-height: 20px;
    font-size: 16px;
    margin-top: 7px;
    margin-left: 10px;
    @include breakpoint(large-laptop) {
      font-size: 14px;
      margin-top: 4px;
    }
    @include breakpoint(small-laptop) {
      font-size: 12px;
    }
  }
  .form-check-input {
    width: 24px;
    height: 24px;
    cursor: pointer;
    @include breakpoint(large-laptop) {
      width: 18px;
      height: 18px;
    }
    &:checked {
      background-color: $success;
      border-color: $success;
    }
  }
  &.form-check-mini {
    font-size: 14px;
    margin-top: 0;
    @include breakpoint(large-laptop) {
      font-size: 12px;
    }
    label {
      line-height: 16px;
      font-size: 14px;
      margin-top: 0;
      @include breakpoint(large-laptop) {
        font-size: 12px;
      }
    }
    .form-check-input {
      width: 18px;
      height: 18px;
    }
  }
}

.input-group {
  .input-group-text {
    background-color: #f2f5f6;
    border-color: #e5e9ec;
    img {
      max-height: 25px;
    }
  }
  .form-control {
    background-color: #f2f5f6;
    border-left: 1px solid #f2f5f6;
    &:focus {
      border-left: 1px solid $secondary2;
    }
  }
}

/*************************************
    TABS
*************************************/
.nav-tabs {
  .nav-link {
    color: $primary;
  }
  .nav-item.show .nav-link,
  .nav-link.active {
    color: $primary;
  }
  .nav-link.active {
    font-weight: bold;
  }
}

/*************************************
    SCROLLSPY
*************************************/
.list-group-item + .list-group-item {
  border-top-width: 1px;
}
.list-group-item + .list-group-item.active {
  margin-top: unset;
}

/*************************************
    ROUNDED CONRNERS
*************************************/
.rounded-1 {
  border-radius: 4px !important;
}
.rounded-2 {
  border-radius: 8px !important;
}
.rounded-3 {
  border-radius: 16px !important;
}
.rounded-4 {
  border-radius: 24px !important;
}
.rounded-5 {
  border-radius: 48px !important;
}
